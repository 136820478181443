angular
  .module('app.organisation', ['ui.router', 'pascalprecht.translate'])
  .config(organizationModuleConfig);

function organizationModuleConfig($stateProvider) {
  $stateProvider.state('root.organisation', {
    url: '/organisation',

    redirectTo: (params, $injector) => {
      const UserService = $injector.get('UserService');

      switch (UserService.getActiveAccount().type) {
        case 'agency':
          return 'root.organisation.agency';

        default:
          return 'root.organisation.rating';
      }
    },

    views: {
      'contentView@root': {
        templateUrl: 'organisation/views/root.html',
      },
    },

    resolve: {
      lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
        return MyLazyLoad.load($ocLazyLoad, [
          '/app/js/organisation/sources.js',
          '/app/js/profile/sources.js',
          '/app/js/player/sources.js',
          '/app/js/agencies/sources.js',
          '/app/js/communication/sources.js',
          '/app/js/player-rating/sources.js',
        ]);
      },
      title: function (Tabs) {
        Tabs.setWindowTitle('Organization');
      },
    },
  });

  $stateProvider.state('root.organisation.agency', {
    url: '',
    bastract: true,

    redirectTo: 'root.organisation.agency.clients',

    resolve: {
      permissions: requirePermissions('agency-organization', ['organization-access']),
    },
  });

  $stateProvider.state('root.organisation.agency.clients', {
    url: '/clients',

    views: {
      'content@root.organisation': {
        template: '<organization-agency-client-list>',
      },
    },
  });

  $stateProvider.state('root.organisation.agency.client', {
    url: '/client/:id',

    views: {
      'content@root.organisation': {
        template: '<organization-agency-client-details player-id="$resolve.params.id">',
      },
    },

    resolve: {
      params: function ($stateParams) {
        return $stateParams;
      },
    },
  });

  $stateProvider.state('root.organisation.agency.new_client', {
    url: '/new-client',

    views: {
      'content@root.organisation': {
        template: '<organization-agency-new-client>',
      },
    },
  });

  //   $stateProvider.state('root.organisation.team', {
  //     url: '/team',

  //     views: {
  //       'content@root.organisation': {
  //         templateUrl: 'organisation/views/OrganisationTeam.html',
  //       },
  //     },

  //     resolve: {
  //       permissions: requirePermissions(GENERAL_ORGANIZATION_ACCESS_PERMISSIONS),
  //     },
  //   });

  //   $stateProvider.state('root.organisation.budget', {
  //     url: '/budget',

  //     views: {
  //       'content@root.organisation': {
  //         templateUrl: 'organisation/views/OrganisationBudget.html',
  //       },
  //     },

  //     resolve: {
  //       permissions: requirePermissions(GENERAL_ORGANIZATION_ACCESS_PERMISSIONS),
  //     },
  //   });

  $stateProvider
    .state('root.organisation.rating', {
      url: '/rating?team&selectedSeasonId',

      views: {
        'content@root.organisation': {
          templateUrl: 'organisation/views/OrganisationRating.html',
          controller: 'OrganisationRatingController',
          controllerAs: '$organisationCtrl',
        },
      },

      resolve: {
        permissions: requirePermissions('team-organization', ['player-rating']),
      },
    })
    .state('root.organisation.rating.doRating', {
      url: '/:seasonId/:homeId/:awayId/:date',

      views: {
        'content@root.organisation': {
          templateUrl: 'organisation/views/OrganisationDoRating.html',
          controller: 'OrganisationRatingController',
          controllerAs: '$organisationCtrl',
        },
      },

      resolve: {
        permissions: requirePermissions('team-organization', ['player-rating']),
      },
    });

  //   $stateProvider.state('root.organisation.requests', {
  //     url: '/requests',

  //     views: {
  //       'content@root.organisation': {
  //         templateUrl: 'organisation/views/OrganisationRequests.html',
  //       },
  //     },

  //     resolve: {
  //       permissions: requirePermissions(GENERAL_ORGANIZATION_ACCESS_PERMISSIONS),
  //     },
  //   });
}

function requirePermissions(orgType: string, permissions: string[]) {
  return [
    'ACLService',
    function (ACLService) {
      return ACLService.isOrganizationOfTypePromise(orgType).then(function () {
        return ACLService.hasAccountPermissionsPromise(permissions, false);
      });
    },
  ];
}
